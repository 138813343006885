import { Link } from "gatsby-plugin-react-i18next";
import React from "react";

const Promise = ({ promiseContent, differentStyle, promiseHeadline }) => {
  return (
    <section className={`m-promise ${differentStyle ? differentStyle : ""}`}>
      <div className="_wr">
        <div className="m-promise__topContent">
          <h2 className="m-promise__topContent--title">{promiseHeadline}</h2>
          <span className="a-line -red"></span>
        </div>
        <div className="_w m-promise__cardsContainer">
          {promiseContent.map(
            ({ title, textOne, textTwo, icon, url, btnText }, i) => (
              <div className="_m6 _xl4 m-promise__itemContent" key={i}>
                {url && (
                  <Link to={url}>
                    <div className="m-promise__item">
                      <div className="m-promise__item--header">
                        <div className="m-promise__item--icon">{icon}</div>
                        <h3 className="m-promise__item--title">{title}</h3>
                      </div>
                      <p
                        className={`m-promise__item--text ${
                          textTwo ? "" : "-solo"
                        }`}
                      >
                        {textOne}
                      </p>
                      {textTwo ? (
                        <p className="m-promise__item--text">{textTwo}</p>
                      ) : (
                        ""
                      )}

                      <div className="m-cardLinks">
                        <button className="a-btn -tertiary">{btnText}</button>
                        <span className="a-arrow -long"></span>
                      </div>
                    </div>
                  </Link>
                )}
                {!url && (
                  <div className="-item">
                    <div className="m-promise__item">
                      <div className="m-promise__item--header">
                        <div className="m-promise__item--icon">{icon}</div>
                        <h3 className="m-promise__item--title">{title}</h3>
                      </div>
                      <p
                        className={`m-promise__item--text ${
                          textTwo ? "" : "-solo"
                        }`}
                      >
                        {textOne}
                      </p>
                      {textTwo ? (
                        <p className="m-promise__item--text">{textTwo}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Promise;
