import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import { ModalContext } from "../../context/ModalContext";

const query = graphql`
  query {
    file(relativePath: { eq: "images/global/develop.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Develop = ({ cssModifer }) => {
  const { t } = useTranslation();

  const { openModal, setOpenModal } = useContext(ModalContext);

  const data = useStaticQuery(query);
  const img = data.file.childImageSharp.fluid;

  return (
    <section className={`m-develop ${cssModifer ? cssModifer : ""}`}>
      {/* <BackgroundImage fluid={img} Tag="div" className="_wr m-develop__wrapper"> */}
      <div className="_wr m-develop__wrapper">
        <div className="_w m-develop__content">
          <div className="_l8">
            <h2 className="m-develop__content--title">
              {t("develop.headline")}
            </h2>
            <span className="a-line -red"></span>
            <p className="m-develop__content--text">{t("develop.text")}</p>
            <span className="a-btn -primary" onClick={setOpenModal}>
              {t("buttons.contactUs")}
            </span>
          </div>
        </div>
      </div>
      {/* </BackgroundImage> */}
    </section>
  );
};

export default Develop;
