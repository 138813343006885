import React from "react";
import PropTypes from "prop-types";

const Text = ({ textContent, rowWidth, backgroundColor, cssModifer }) => {
  return (
    <section
      className={`m-text ${backgroundColor ? backgroundColor : ""} ${
        cssModifer ? cssModifer : ""
      }`}
    >
      <div className="_wr">
        <div className={`_w m-text__content ${cssModifer ? cssModifer : ""}`}>
          <div className={`${rowWidth ? rowWidth : "_l8"}`}>
            <h2 className="m-text__content--title">{textContent.title}</h2>
            <span className="a-line -red"></span>
            <p
              className="m-text__content--text -tech"
              dangerouslySetInnerHTML={{ __html: textContent.text }}
            />
            {textContent.secondText ? (
              <p
                className="m-text__content--text -tech"
                dangerouslySetInnerHTML={{ __html: textContent.secondText }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

Text.defaultProps = {
  title: "Lorem ipsum dolor sit amet.",
  text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, tempore?",
};

Text.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Text;
