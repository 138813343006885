import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Text from "../../components/global/text";
import Develop from "../../components/global/develop";
import HeaderGlobal from "../../components/headers/header-global";
import { graphql } from "gatsby";
import Process from "../../components/global/process";
import PromiseOne from "../../assets/images/global/promise-1-app.svg";
import PromiseTwo from "../../assets/images/global/promise-2-app.svg";
import PromiseThree from "../../assets/images/global/promise-3-app.svg";
import Promise from "../../components/global/promise";
import HeaderBcg from "../../assets/images/headers/app-re.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Projects from "../../components/global/projects";

const EngineeringTeam = () => {
  const { t } = useTranslation();
  // Header Content
  const hTitle = `${t("services.reEng.headline")}`;
  const hTextOne = `${t("services.reEng.firstText")}`;

  const textContent2 = {
    title: `${t("services.appDev.ourApproach.headline")}`,
    text: `${t("services.appDev.ourApproach.text")}`,
  };

  const promiseHeadline = `${t("services.reEng.promise.headline")}`;
  // Promise Component
  const promiseContent = [
    {
      title: `${t("services.reEng.promise.first.title")}`,
      textOne: `${t("services.reEng.promise.first.firstText")}`,
      // textTwo: `${t("services.reEng.promise.first.secondText")}`,
      icon: <PromiseOne />,
    },
    {
      title: `${t("services.reEng.promise.second.title")}`,
      textOne: `${t("services.reEng.promise.second.firstText")}`,
      // textTwo: `${t("services.reEng.promise.second.secondText")}`,
      icon: <PromiseTwo />,
    },
    {
      title: `${t("services.reEng.promise.third.title")}`,
      textOne: `${t("services.reEng.promise.third.firstText")}`,
      // textTwo: `${t("services.reEng.promise.third.secondText")}`,
      icon: <PromiseThree />,
    },
  ];

  // Process Component
  const processShortText = `${t("services.appDev.process.shortText")}`;

  const processContent = [
    {
      title: `${t("services.reEng.process.first.title")}`,
      text: `${t("services.reEng.process.first.text")}`,
    },
    {
      title: `${t("services.reEng.process.second.title")}`,
      text: `${t("services.reEng.process.second.text")}`,
    },
    {
      title: `${t("services.reEng.process.third.title")}`,
      text: `${t("services.reEng.process.third.text")}`,
    },
    {
      title: `${t("services.reEng.process.fourth.title")}`,
      text: `${t("services.reEng.process.fourth.text")}`,
    },
  ];
  return (
    <Layout>
      <Seo title="Application RE-engineering" />
      <HeaderGlobal hTitle={hTitle} hTextOne={hTextOne} image={HeaderBcg} />
      <Promise
        promiseContent={promiseContent}
        promiseHeadline={promiseHeadline}
        differentStyle="-promiseTitle -app"
      />
      <Text
        textContent={textContent2}
        backgroundColor="-approach -semantic"
        cssModifer="-homeText"
      />
      <Process
        processContent={processContent}
        processShortText={processShortText}
        backgroundColor="-borderBlack -semantic -app-re"
      />
      <Projects />
      <Develop cssModifer="-whiteBg" />
    </Layout>
  );
};

export default EngineeringTeam;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
