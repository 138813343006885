import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Process = ({ processContent, processShortText, backgroundColor }) => {
  const { t } = useTranslation();

  return (
    <section
      className={`m-process section-padding ${
        backgroundColor ? backgroundColor : ""
      }`}
    >
      <div className="_wr">
        <p className="m-process__shortText">{processShortText}</p>
        <div className="m-process__content">
          {processContent.map(({ title, text }, i) => {
            return (
              <div className="m-process__item" key={i}>
                <div className="m-process__item--titleDiv">
                  <h3
                    className="-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </div>
                <div
                  className={
                    i === 3
                      ? "m-process__item--numText -last"
                      : "m-process__item--numText"
                  }
                >
                  <div className="-num">{<h2>0{i + 1}</h2>}</div>
                  <p className="m-process__item--text">{text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Process;
