import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LogoEaro from "../../assets/images/global/earo.png";
import LogoQcc from "../../assets/images/global/grundfos.png";
import "swiper/css";
import { Link } from "gatsby-plugin-react-i18next";

const Projects = ({ cssModifer }) => {
  const { t } = useTranslation();

  const projects = [
    {
      image: LogoQcc,
      title: `${t("projects.list.qcc.headline")}`,
      text: `${t("projects.list.qcc.text")}`,
      linkTo: "/case-studies/quality-control/",
      modifier: "-qcc",
    },
    {
      image: LogoEaro,
      title: `${t("projects.list.earo.headline")}`,
      text: `${t("projects.list.earo.text")}`,
      linkTo: "/case-studies/e-aro/",
      modifier: "-earo",
    },
  ];

  return (
    <section
      className={`m-projects section-padding ${cssModifer ? cssModifer : ""}`}
    >
      <div className="_wr">
        <div className="m-projects__title">
          <h2 className="m-projects__title">{t("projects.headline")}</h2>
          <span className="a-line -red"></span>
        </div>
        {/* <p className="a-text -black">{t("projects.text")}</p> */}
        <div className="_w">
          {projects.map(({ image, title, text, linkTo, modifier }, key) => (
            <div className="m-projects__cards _l6" key={key}>
              <Link to={linkTo}>
                <div className={`m-projects__card ${modifier}`}>
                  <div className="m-projects__card--imageContainer">
                    <div className="m-projects__card--image">
                      <img src={image} alt={title} />
                    </div>
                  </div>
                  <div className="m-projects__card--content">
                    <h3 className="m-projects__card--title">{title}</h3>
                    <p className="m-projects__card--text">{text}</p>
                    <div className="m-cardLinks">
                      <span className="a-btn -tertiary">
                        {t("buttons.discoverCaseStudy")}
                      </span>
                      <span className="a-arrow -long"></span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
