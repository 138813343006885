import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";

const HeaderGlobal = ({ hTitle, hSubtitle, hTextOne, hTextTwo, image }) => {
  return (
    <header className="m-headerGlobal">
      <div
        className="_wr m-headerGlobal__wrapper"
        // style={{ backgroundImage: `url("${image}")` }}
      >
        <div className="_w m-headerGlobal__content">
          <div className="_xs10 _s9 _m7 _l6">
            <h1 className="m-headerGlobal__content--title">{hTitle}</h1>
            <p className="m-headerGlobal__content--text -first">{hTextOne}</p>
          </div>
          <div
            className="m-headerMarkets__bkg -global _m5 _xl6"
            style={{ backgroundImage: `url("${image}")` }}
          ></div>
        </div>
      </div>
    </header>
  );
};

HeaderGlobal.defaultProps = {
  hTitle: "Lorem ipsum",
  hTextOne:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, tempore?",
};

HeaderGlobal.propTypes = {
  hTitle: PropTypes.string,
  hSubtitle: PropTypes.string,
  hTextOne: PropTypes.string,
  hTextTwo: PropTypes.string,
};

export default HeaderGlobal;
